import { useEffect, useState } from 'react';

export function useCurrentPath() {
  const [currentPath, setCurrentPath] = useState('');

  useEffect(() => {
    function updatePath() {
      setCurrentPath(window.location.pathname);
    }

    // Set initial path
    updatePath();

    // Listen for browser back/forward navigation
    window.addEventListener('popstate', updatePath);
    // Listen for Astro's client-side navigation
    document.addEventListener('astro:after-navigation', updatePath);

    return () => {
      window.removeEventListener('popstate', updatePath);
      document.removeEventListener('astro:after-navigation', updatePath);
    };
  }, []);

  return currentPath;
}
